import {
  IconBuildingStore,
  IconCreditCard,
  IconHeadset,
  IconHome,
  IconLogout,
  IconReceipt,
  IconUser,
  IconUsers
} from '@tabler/icons'
import Link from 'next/link'

import { Site as SiteType, User as UserType } from '@/types'

export const menuItems = (siteData: SiteType, user: UserType | undefined) => [
  { label: 'Minha Conta', section: true },
  {
    label: 'Comprar Agora',
    active: true,
    disabled: false,
    color: undefined,
    icon: (size: number) => <IconBuildingStore size={size} />,
    rightSection: undefined,
    component: Link,
    href: '/accounts/profiles',
    description: 'Acessar lojas para comprar produtos e serviços.'
  },
  {
    label: 'Meus Alunos',
    active: true,
    disabled: false,
    color: undefined,
    icon: (size: number) => <IconUsers size={size} />,
    rightSection: undefined,
    component: Link,
    href: '/accounts/enrollments',
    description: 'Alunos vinculado ao responsável.',
    isStaff: user?.isStaff,
    hidden: !siteData?.hasSchoolManagementConfiguration && !user?.isStaff
  },
  {
    label: 'Meus Dados',
    active: true,
    disabled: false,
    color: undefined,
    icon: (size: number) => <IconUser size={size} />,
    rightSection: undefined,
    component: Link,
    href: '/accounts/profile',
    description: 'Altere e mantenha seus dados atualizados.'
  },
  {
    label: 'Meus Pedidos',
    active: true,
    disabled: false,
    color: undefined,
    icon: (size: number) => <IconReceipt size={size} />,
    rightSection: undefined,
    component: Link,
    href: '/accounts/orders',
    description: 'Acesse notas fiscais, pagamentos, cancelamento e devolução.'
  },
  {
    label: 'Meus Endereços',
    active: true,
    disabled: false,
    color: undefined,
    icon: (size: number) => <IconHome size={size} />,
    rightSection: undefined,
    component: Link,
    href: '/accounts/addresses',
    description: 'Gerencie seus endereços.'
  },
  {
    label: 'Meus Cartões de Crédito',
    active: true,
    disabled: false,
    color: undefined,
    icon: (size: number) => <IconCreditCard size={size} />,
    rightSection: undefined,
    component: Link,
    href: '/accounts/credit-cards',
    description: 'Gerencie seus cartões de crédito.'
  },
  { divider: true },
  { label: 'Site', section: true },
  {
    label: 'Central de Ajuda',
    active: true,
    disabled: false,
    color: undefined,
    icon: (size: number) => <IconHeadset size={size} />,
    rightSection: undefined,
    component: Link,
    href: '/help',
    description: undefined
  },
  {
    label: 'Sair',
    active: true,
    disabled: false,
    color: undefined,
    icon: (size: number) => <IconLogout size={size} />,
    rightSection: undefined,
    component: Link,
    href: '/accounts/logout',
    description: undefined
  }
].filter(item => !item.hidden)
