import {
  ActionIcon,
  Avatar,
  Box,
  createStyles,
  Group,
  Indicator,
  Menu,
  Skeleton,
  Text,
  UnstyledButton
} from '@mantine/core'
import { IconChevronDown, IconChevronUp } from '@tabler/icons'
import Link from 'next/link'
import { Fragment, useState } from 'react'

import { useAuth } from '@/providers/AuthProvider'
import { Site as SiteType } from '@/types'

import { menuItems } from './constants'

const useStyles = createStyles(theme => ({
  button: {
    display: 'flex',
    height: '100%',
    maxHeight: '100%',
    borderRadius: theme.radius.sm
  },
  text: {
    maxWidth: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  noUser: {
    display: 'flex',
    gap: theme.spacing.xs,
    alignItems: 'center',
    width: '100%',
    maxWidth: 200,
    height: '100%'
  }
}))

export default function UserToggle({ siteData }: { siteData: SiteType }) {
  // Hooks
  const { classes } = useStyles()
  const { userData, isValidating } = useAuth() || {}

  // Constants
  const { user } = userData || {}

  // States
  const [isOpen, setIsOpen] = useState(false)

  return !user ? (
    <Box className={classes.noUser}>
      <Skeleton height={40} circle />
      <Box sx={{ flexGrow: 1 }}>
        <Skeleton height={8} radius="xl" />
        <Skeleton height={6} width="80%" mt={4} radius="xl" />
      </Box>
    </Box>
  ) : (
    <Menu opened={isOpen} onChange={setIsOpen} width={200} withArrow>
      <Menu.Target>
        <UnstyledButton className={classes.button} component="span">
          <Group noWrap>
            <Avatar size={40} radius="xl" src={user?.picture} alt={user?.firstName}>
              {isValidating && <Skeleton height={40} circle />}
            </Avatar>
            <Box>
              <Text className={classes.text}>
                {user?.firstName}
                {user?.lastName && ` ${user?.lastName}`}
              </Text>
              <Text size="xs" color="dimmed" className={classes.text}>
                {user?.email}
              </Text>
            </Box>
            <ActionIcon>
              {isOpen ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
            </ActionIcon>
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        {menuItems(siteData, user).map((item, index) => {
          if (item.section) {
            return (
              <Menu.Label key={item.label} color="dimmed" mb={4}>
                {item.label}
              </Menu.Label>
            )
          }
          if (item.divider) return <Menu.Divider key={`divider-${index}`} />
          return item.active ? (
            <Menu.Item
              key={item.label}
              icon={item.icon?.(14)}
              color={item.color || 'gray.8'}
              component={item.component || Link}
              href={item.href as string}
              rel="noopener noreferrer">
              <Indicator color="orange" disabled={!item.isStaff} position="top-start">
                {item.label}
              </Indicator>
            </Menu.Item>
          ) : (
            <Fragment key={item.label}></Fragment>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}
